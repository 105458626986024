<template>
  <div class="webgl-output">
      <div class="condition" v-show="!threeDivShow">
        <el-card class="box-card">
            <div class="conditionBtn">
                <el-button type="success" size="medium" @click="add()">添加货物</el-button>
            </div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
              <el-table
                :data="ruleForm.list"
                tooltip-effect="dark"
                :header-cell-style="getRowClass"
                style="width: 100%">
                <el-table-column
                  min-width="200"
                  >
                    <template slot="header">
                     <span>货物名称</span>
                     <i style="color:#fff;">*</i>
                    </template>
                    <template slot-scope="scope">
                      <el-form-item :prop="'list.' +scope.$index+'.name'" :rules="rules.name">
                          <el-input v-model="scope.row.name"></el-input>
                      </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                  min-width="120"
                  >
                    <template slot="header">
                     <span>长度(mm)</span>
                     <i style="color:#fff;">*</i>
                    </template>
                    <template slot-scope="scope">
                      <el-form-item :prop="'list.' +scope.$index+'.length'" :rules="rules.length">
                          <el-input v-model="scope.row.length"></el-input>
                      </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                  min-width="120"
                  >
                    <template slot="header">
                     <span>宽度(mm)</span>
                     <i style="color:#fff;">*</i>
                    </template>
                    <template slot-scope="scope">
                      <el-form-item :prop="'list.' +scope.$index+'.width'" :rules="rules.width">
                          <el-input v-model="scope.row.width"></el-input>
                      </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                  min-width="120"
                  >
                    <template slot="header">
                     <span>高度(mm)</span>
                     <i style="color:#fff;">*</i>
                    </template>
                    <template slot-scope="scope">
                      <el-form-item :prop="'list.' +scope.$index+'.height'" :rules="rules.height">
                          <el-input v-model="scope.row.height"></el-input>
                      </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                  min-width="120"
                  >
                    <template slot="header">
                     <span>重量(kg)</span>
                     <i style="color:#fff;">*</i>
                    </template>
                    <template slot-scope="scope">
                      <el-form-item :prop="'list.' +scope.$index+'.weight'" :rules="rules.weight">
                          <el-input v-model="scope.row.weight"></el-input>
                      </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                  min-width="120"
                  >
                    <template slot="header">
                     <span>数量</span>
                     <i style="color:#fff;">*</i>
                    </template>
                    <template slot-scope="scope">
                      <el-form-item :prop="'list.' +scope.$index+'.quantity'" :rules="rules.quantity">
                          <el-input v-model="scope.row.quantity"></el-input>
                      </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                    class-name="colorTd"
                    min-width="110"
                  >
                    <template slot="header">
                     <span>颜色</span>
                     <i style="color:#fff;">*</i>
                    </template>
                    <template slot-scope="scope">
                      <el-form-item :prop="'list.' +scope.$index+'.color'" :rules="rules.color">
                        <el-color-picker v-model="scope.row.color" size="small" :predefine="predefineColors"></el-color-picker>
                      </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  min-width="65"
                  class-name="op"
                    >
                    <template slot-scope="scope">
                        <span class="el-icon-delete del" @click="del(scope.$index)"></span>
                    </template>
                </el-table-column>
              </el-table>
          </el-form>
        </el-card>
        <div class="subBtn">
            <el-button type="warning" size="medium" @click="sub">提 交</el-button>
        </div>
      </div>
      <div class="webgl" ref="webgl"  v-show="threeDivShow">
        <div class="tag">
            <p v-show="msg.name">
                <label>货物名称:</label>
                <span>{{msg.name}}</span>
            </p>
            <p v-show="msg.type">
                <label>箱型:</label>
                <span>{{msg.type}}</span>
            </p>
            <p>
                <label>长:</label>
                <span>{{msg.length}}/mm</span>
            </p>
            <p>
                <label>宽:</label>
                <span>{{msg.width}}/mm</span>
            </p>
            <p>
                <label>高:</label>
                <span>{{msg.height}}/mm</span>
            </p>
            <p>
                <label>容量:</label>
                <span>{{msg.weight}}/KG</span>
            </p>
        </div>
        <div class="closeBtn">
            <el-button type="danger" @click="threeDivShow = false;delScence()">关闭</el-button>
        </div>
      </div>
  </div>
</template>

<script>
import Binning from "./Binning.js";
export default Binning;
</script>

<style>
@import "./Binning.css";
</style>