import Binning from "./Binning.vue";
import * as Three from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
export default {
  component: {
    Binning,
  },
    data() {
        var validateColor = (rule, value, callback) => {
            if (value === null || value === '') {
                callback(new Error('请选择颜色'));
            } else {
                for (let i = 0; i < this.ruleForm.list.length; i++) {
                    if (this.ruleForm.list[i+1] && this.ruleForm.list[i].color == this.ruleForm.list[i+1].color ) {
                        callback(new Error('请选择不同的颜色'));
                    } else {
                        callback();
                    }
                    
                }
            }
        };
    return {
        scene: null, // 场景对象
        camera: null, // 相机对象
        mesh: null, // 网格对象
        maxHeight: 500, //
        maxWidth: "100%", //
        renderer: "",
        msgDiv: false,
        msg: {
            name: "",
            type: "20GP",
            length: "10",
            width: "20",
            height: "30",
            weight: "100",
        },
        temporary: [],
        colors: "",
        mouse: new Three.Vector2(),
        raycaster: new Three.Raycaster(),
        ruleForm: {
            list: [
                {
                    name: '测试货物',
                    length: '10',
                    width: '20',
                    height: '20',
                    weight: '15',
                    quantity: '3',
                    color: '#ef7609'
                },
            ],
        },
        rules: {
            name: [{ required: true, message: '请输入货物名称', trigger: 'blur' }],
            length: [{ required: true, message: '请输入长度', trigger: 'blur' }],
            width: [{ required: true, message: '请输入宽度', trigger: 'blur' }],
            height: [{ required: true, message: '请输入高度', trigger: 'blur' }],
            weight: [{ required: true, message: '请输入重量', trigger: 'blur' }],
            quantity: [{ required: true, message: '请输入数量', trigger: 'blur' }],
            color: [{ validator: validateColor, trigger: 'change' }]
        },
        predefineColors: [ // 颜色选择器默认颜色
            '#ff4500',
            '#ff8c00',
            '#ffd700',
            '#90ee90',
            '#00FF95',
            '#00ced1',
            '#1e90ff',
            '#0724FE',
            '#c71585',
            '#07FE3D',
            '#4107FE'
        ],
        threeDivShow: false,
        jzbox: [{
            id: 1,
            name: '20gp',
            width: 2350,
            length: 5900,
            height: 2655,
            weight: 28000
            }, {
            id: 2,
            name: '20hq',
            width: 2350,
            length: 5900,
            height: 2390,
            weight: 28200
            }, {
            id: 3,
            name: '30gp',
            width: 2340,
            length: 8940,
            height: 2655,
            weight: 28200
            }, {
            id: 4,
            name: '30hq',
            width: 2340,
            length: 8900,
            height: 2360,
            weight: 28400
            }, {
            id: 5,
            name: '40gp',
            width: 2350,
            length: 12030,
            height: 2655,
            weight: 28600
            }, {
            id: 6,
            name: '40hq',
            width: 2350,
            length: 12030,
            height: 2390,
            weight: 28800
            }, {
            id: 7,
            name: '45gp',
            width: 2340,
            length: 13550,
            height: 2655,
            weight: 28000
            }, {
            id: 8,
            name: '45hq',
            width: 2340,
            length: 13550,
            height: 2360,
            weight: 27800
        }]
        
        };
    },
    created() {
    },
    mounted() {
        this.initScene(); // 初始化场景
    },
    methods: {
        getRowClass() { 
            return "background:#ee9e58;color:#fff"
        },
        add() {
            var c ="#";
            var cArray=["0","1","2","3","4","5","6","7","8","9","A","B","C","D","E","F"];
            for(var i=0;i<6;i++){
              var  cIndex= Math.round(Math.random()*15);
                c+=cArray[cIndex];
            }
            this.ruleForm.list.push({
                name: 'new',
                length: '10',
                width: '10',
                height: '10',
                weight: '1',
                quantity: '1',
                color: c
              })
        },
        del(index) {
            this.ruleForm.list.splice(index,1)
        },
        async sub() {
            this.$refs.ruleForm.validate(async valid => {
                if (valid) {
                    // 判断用户输入的货物信息是否正确
                    await this.ruleForm.list.forEach((item,index) => {
                        if (+item.weight > 28800) {
                            return this.$message({
                                type: 'warning',
                                message: "第"+ (index + 1) + '个货物重量超过集装箱的最大载重',
                                center:true,
                                showClose: true,
                            });
                        }else {
                            // 一个货物有4种摆放方式，拿4种摆放方式和箱子的长宽高比较，看是否可以赛进箱子
                            let num = 0
                            this.jzbox.forEach(element => {
                                if ((+item.length <= +element.length && +item.width <= +element.width && +item.height <= +element.height) || (+item.width <= +element.length && +item.length <= +element.width && +item.height <= +element.height) || (+item.height <= +element.length && +item.length <= +element.width && +item.width <= +element.height) || (+item.height <= +element.length && +item.width <= +element.width && +item.length <= +element.height)) {
                                    num++
                                }
                            })
                            if (num == 0) {
                                return this.$message({
                                    type: 'warning',
                                    message: "第"+ (index + 1) + '个货物没有合适的箱型',
                                    center:true,
                                    showClose: true,
                                });
                            } 
                        }
                    })
                    this.threeDivShow = true
                    await this.init()
                
                }
            })
        },
        
    init() {
        // 以20gp为例
        for (let j = 0; j < this.ruleForm.list.length; j++) { //循环货物种类
            var item = this.ruleForm.list[j]
            for (let i = 0; i < +item.quantity; i++) { // 循环每种货物件数
                console.log(i * item.length);
                this.box(item.length, item.width, item.height, i * item.length, 0,  0, item.color, item.name)
            }
        }
        this.contanner(); // 创建最外层的箱子
        this.renender(); // 渲染页面
    },
    // 初始化场景方法
    initScene() {
        this.scene = new Three.Scene();
        /**
       * 光源设置
       */
      // 点光源
      const point = new Three.PointLight(0xffffff);
      point.position.set(400, 200, 300); // 点光源位置
      this.scene.add(point); // 点光源添加到场景中
      // 环境光
      const ambient = new Three.AmbientLight(0x444444);
      this.scene.add(ambient);
      /**
       * 相机设置
       */
      const width = window.innerWidth; // 窗口宽度
      const height = window.innerHeight; // 窗口高度
      const k = width / height; // 窗口宽高比
      const s = 200; // 三维场景显示范围控制系数，系数越大，显示的范围越大
      // 创建相机对象
      this.camera = new Three.OrthographicCamera(-s * k, s * k, s, -s, 1, 1000);
      // camera = new THREE.PerspectiveCamera(70, width / height, 1, 1000);
      this.camera.position.set(100, 100, 300); // 设置相机位置
      this.camera.lookAt(this.scene.position); // 设置相机方向(指向的场景对象)
      /**
       * 创建渲染器对象
       */
      this.renderer = new Three.WebGLRenderer();
      this.renderer.setSize(width, height); // 设置渲染区域尺寸
      this.renderer.setClearColor(0xb9d3ff, 1); // 设置背景颜色
      this.$refs.webgl.appendChild(this.renderer.domElement); // body元素中插入canvas对象
    },
    // 创建最外层的箱子
    contanner() {
      // 点
      const geometry1 = new Three.BufferGeometry(); // 创建一个Buffer类型几何体对象
      // 类型数组创建顶点数据
      const vertices = new Float32Array([
        // 线
        100, 50, -50,
        100, 50, 50,
        100, 50, -50,
        100, -50, -50,
        -100, -50, -50,
        -100, 50, -50,
        100, 50, -50,
        100, 50, 50,
        -100, 50, 50,
        -100, -50, 50,
        -100, -50, -50,
        -100, 50, -50,
        -100, 50, 50,
        -100, -50, 50,
        100, -50, 50,
        100, -50, 50,
        100, -50, -50,
        100, -50, 50,
        100, 50, 50
      ]);
      // 创建属性缓冲区对象
      const attribue = new Three.BufferAttribute(vertices, 3); // 3个为一组，表示一个顶点的xyz坐标
      // 设置几何体attributes属性的位置属性
      geometry1.attributes.position = attribue;
      const material1 = new Three.LineBasicMaterial({
        color: 0xff0000, // 线条颜色
      }); // 材质对象
      // material1.name = '集装箱'
      const points = new Three.Line(geometry1, material1); // 网格模型对象Mesh
      this.scene.add(points); // 点对象添加到场景中
    },

    // 渲染页面
    renender() {
      
      this.render();
      const controls = new OrbitControls(this.camera, this.renderer.domElement); // 创建控件对象
      controls.addEventListener("change", this.render); // 监听鼠标、键盘事件

      window.addEventListener("mousemove", this.onMouseMove, false);

      window.addEventListener("click", this.onMouseClick, true);
    },
    
    // 执行渲染操作
    render() {
      requestAnimationFrame(this.render);
      this.renderer.render(this.scene, this.camera); // 执行渲染操作 指定场景、相机作为参数
    },
    // 计算三维坐标对应的屏幕坐标
    // divRender() {
    //   // 计算三维坐标对应的屏幕坐标
    //   // const position = new THREE.Vector3(-280, 0, 0);
    //   // const windowPosition = this.transPosition(position);
    //   // const left = windowPosition.x;
    //   // const top = windowPosition.y;
    //   // 设置div屏幕位置
    //   this.msgDiv = true;
    // },
    // 三位坐标转屏幕坐标的方法
    transPosition(position) {
      const worldVector = new Three.Vector3(position.x, position.y, position.z);
      const vector = worldVector.project(this.camera);
      const halfWidth = window.innerWidth / 2;
      const halfHeight = window.innerHeight / 2;
      return {
        x: Math.round(vector.x * halfWidth + halfWidth),
        y: Math.round(-vector.y * halfHeight + halfHeight),
      };
    },

    // 创建货物模型
    /**
     *
     * @param {货物的长} length == x
     * @param {货物的宽} width == y
     * @param {货物的高} height == z
     * @param {货物偏移X坐标的长度} x
     * @param {货物偏移y坐标的长度} y
     * @param {货物偏移z坐标的长度} z
     * @param {货物颜色} color
     */
    box(length, width, height, x = 0, y = 0, z = 0, color, ebNo) {
      const geometry = new Three.BoxGeometry(length, height, width);//创建一个立方几何体对象Geometyy
      const material = new Three.MeshBasicMaterial({
        color,
      });//材质对象Material
      material.name = ebNo;
      const rect = new Three.Mesh(geometry, material);//网格模型对象Mesh
      rect.position.set(
        -(100 - length / 2) + x,
        -(50 - height / 2) + y,
        50 - width / 2 + z
      ); // 设置mesh3模型对象的xyz坐标为120,0,0
      this.scene.add(rect);//网格模型添加到场景中

      const geometry1 = new Three.BufferGeometry(); // 创建一个Buffer类型几何体对象
      // 类型数组创建顶点数据
      const vertices = new Float32Array([
        // 线
        (length / 2), (height / 2), -(width / 2),
        (length / 2), (height / 2), (width / 2),
        (length / 2), -(height / 2), (width / 2),
        (length / 2), -(height / 2), -(width / 2),
        (length / 2), (height / 2), -(width / 2),
        -(length / 2), (height / 2), -(width / 2),
        -(length / 2), -(height / 2), -(width / 2),
        (length / 2), -(height / 2), -(width / 2),
        (length / 2), -(height / 2), (width / 2),
        -(length / 2), -(height / 2), (width / 2),
        -(length / 2), -(height / 2), -(width / 2),
        -(length / 2), -(height / 2), (width / 2),
        -(length / 2), (height / 2), (width / 2),
        -(length / 2), (height / 2), -(width / 2),
        -(length / 2), (height / 2), (width / 2),
        (length / 2), (height / 2), (width / 2)
      ]);
      // 创建属性缓冲区对象
      const attribue = new Three.BufferAttribute(vertices, 3); // 3个为一组，表示一个顶点的xyz坐标
      // 设置几何体attributes属性的位置属性
      geometry1.attributes.position = attribue;
      const material1 = new Three.LineBasicMaterial({
        color: 0xffffff, // 线条颜色
      }); // 材质对象
      const points = new Three.Line(geometry1, material1); // 网格模型对象Mesh
      points.position.set(
        -(100 - length / 2) + x,
        -(50 - height / 2) + y,
        50 - width / 2 + z
      ); // 设置mesh3模型对象的xyz坐标为120,0,0
      this.scene.add(points); // 点对象添加到场景中
    },
    onMouseClick(event) {
        if (this.temporary.length) {
            this.temporary[this.temporary.length - 1].object.material.color.set(
            this.colors );
        }
        // 通过鼠标点击的位置计算出raycaster所需要的点的位置s，以屏幕中心为原点，值的范围为-1到1.
        this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
        // 通过鼠标点的位置和当前相机的矩阵计算出raycaster
        this.raycaster.setFromCamera(this.mouse, this.camera);
        // 获取raycaster直线和所有模型相交的数组集合
        const intersects = this.raycaster.intersectObjects(this.scene.children);
        if (intersects.length && intersects[0].object.material.name) {
            this.temporary.push(intersects[0]);
            this.colors = JSON.parse(JSON.stringify(intersects[0].object.material.color))
            intersects[0].object.material.color.set(0xff0000)
            this.ruleForm.list.forEach(item => {
                if (item.name == intersects[0].object.material.name) {
                    this.msg = item
                }
            })   
        } else {
            this.msg = {
                type: '20GP',
                length: '2350',
                width: '5900',
                height: '2390',
                weight: '28200'
            }
        // }
        // var lists = {}
        // this.ruleForm.list.forEach(item => {
        //     if (item.name == intersects[0].object.material.name) {
        //         this.msg = item
        //     } else {
        //         console.log( item);
        //      }
        // })   
        // this.msgDiv = true;
      }
    },
    onMouseMove(event) {
      // 将鼠标位置归一化为设备坐标。x 和 y 方向的取值范围是 (-1 to +1)
      this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    },
    // 销毁模型
    delScence() {
        this.scene.children = []
    }
  },
};
